import React, {useState} from "react"
import {apiUrl, req, apiLinkGuidCheckMg} from "api-methods"
import {sDel, sGet, sSet} from "helper-func"
import QRCode from 'react-qr-code'

function App() {

    let ajPost
    let apiAnswer = {notFound: {code: 404, message: 'К сожалению, ссылка устарела или не найдена'}, internal: {code: 500, message: 'Внутренняя ошибка'},}
    let textPack = {
        menuTab : {
            codeActivation: {top: 'Спасибо за покупку продукта Лаборатории Касперского!', bottom: 'После активации продукта вам будет доступна ссылка для скачивания установочного файла и ключ'},
            contacts: {top: 'Провайдер облачных (подписочных) сервисов в Российской Федерации'},
        }
    }

    // /codeActivation, /services/subscription, [ /kaspersky?view=87499 / /services/subscription?view=87498 ], /account, /account/password, /support, /contacts
    const getTime = () => {             return (new Date).getTime()}
    const getTimeLock = () => {         return getTime() + 200}
    const [timeLock, setTimeLock]       = useState(getTime())
    const [tab, setTab]                 = useState(document.location.href.split('#')[1] ? document.location.href.split('#')[1] : '/codeActivation')
    const [subs, setSubs]               = useState(0)
    const [error, setError]             = useState('')
    const [inputCode, setInputCode]     = useState('') // '', 1111-1111-1111-1111
    const [correctCode, setCorrectCode] = useState(false)
    const [auth, setAuth]               = useState(false) // false // true
    const [pageContent, setPageContent] = useState({})
    const [loader, setLoader]           = useState(false) // false // true
    const [reqOne, setReqOne]           = useState(true) // true
    const Loader                        = () => {return <div className="wrap-lds-ring"><div className="lds-spinner"><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /></div></div>}

    async function ajaxGetData (guid) {
        if (inputCode.length === 19 || true) {
            setLoader(true)
            setError('')
            ajPost = await req({method: apiUrl.tData, apiRule: 'bang', rType: 'GET', noSearch: false, gets: {guid: guid ? guid : inputCode}})
            if (ajPost.code === 0 && ajPost.data) {
                apiLinkGuidCheckMg(ajPost.data, guid ? guid : inputCode)
                sSet("guid", guid ? guid : inputCode)
                setPageContent(ajPost)
                document.location.href = "#/services/subscription"
                setTab('/services/subscription')
                setAuth(true)
                setSubs(0)
                setReqOne(true)
            } else {
                setError(ajPost.message)
                setPageContent(apiAnswer.notFound)
            }
            setLoader(false)
        }
        setTimeLock(getTimeLock())
    }

    function checkCorrectCode (inputCode) {
        setCorrectCode(false)
        if (inputCode.length === 19) {
            let inputCodeSplit = inputCode.split('-')
            if (inputCodeSplit.length === 4) {
                inputCodeSplit.forEach((inputCodeSplit) => {
                    if (inputCodeSplit.length === 4) {
                        if (inputCodeSplit === inputCodeSplit) {
                            setCorrectCode(true)
                        }
                    }
                })
            }
        }
    }
    function inputCodeChange (inputCodeValue) {checkCorrectCode(inputCodeValue);return setInputCode(inputCodeValue)}
    function logOut () {
        setInputCode('')
        setAuth(false)
        setTab('/codeActivation')
        document.location.href = '#/codeActivation'
        sDel("guid")
    }
    function dateConvert(date) {return date.substr(8, 2) + '.' + date.substr(5, 2) + '.' + date.substr(0, 4)}

    if (auth === false) {
        if ((['/services/subscription']).indexOf(tab.substr(0, 22)) > -1) {
            document.location.href = '/lk/#/codeActivation'
        }
    }

    if (tab === '/') {
        document.location.href = '/lk/#/codeActivation'
        setTab('/codeActivation')
    } else if (tab.substr(0,22) === '/services/subscription') {
        let guid = (sGet("guid") + ' ').trim()
        if (guid !== '' && Object.keys(pageContent).length === 0) {
            if (reqOne) {
                setReqOne(false)
                ajaxGetData(guid)
            }
        } else if (guid.length === 0) {
            document.location.href = '/lk/#/codeActivation'
            location.reload()
        }
    }

    return <div className="App">
        <div>
            <div id="menu">
                <div id="menu1">
                    {((sGet("guid") + ' ').trim() === '') && <a href="#/codeActivation" onClick={_ => {setTab('/codeActivation');setInputCode('')}} className={((tab === '/codeActivation') ? 'activeTab' : '')}>Активация кода</a>}
                    {((sGet("guid") + ' ').trim() !== '') && <a href="#/services/subscription" onClick={_ => setTab('/services/subscription')} className={((tab.substr(0, 22) === '/services/subscription') ? 'activeTab' : '')}>Подписки</a>}
                    <a href="#/support" onClick={_ => setTab('/support')} className={((tab === '/support') ? 'activeTab' : '')}>Поддержка</a>
                    <a href="#/contacts" onClick={_ => setTab('/contacts')} className={((tab === '/contacts') ? 'activeTab' : '')}>Контакты</a>
                    {((sGet("guid") + ' ').trim() !== '') && <a onClick={_ => logOut()}>Выход</a>}
                </div>
            </div>
            {Object.keys(pageContent).length === 0 && <div id="menuTitle"><div>{textPack.menuTab[tab] && textPack.menuTab[tab].top}</div><div>{textPack.menuTab[tab] && textPack.menuTab[tab].bottom}</div></div>}

            <div id="content">
                {tab === '/codeActivation' && <div id="codeActivation">
                    <div>Введите полученный промокод</div>
                    <div>Промокод *</div>
                    <div><input value={inputCode} onChange={e => inputCodeChange(e.target.value)} /></div>
                    <div className={inputCode === '' ? '' : 'classOpacity'}>Поле обязательно для заполнения</div>
                    {loader ? <div><Loader /></div> : <div className={(correctCode ? 'activationActive' : 'activationInActive')} onClick={_ => ajaxGetData()}>Продолжить</div>}
                    {error !== '' && <div><br />{error}<br />&nbsp;</div>}
                </div>}
                {tab === '/services/subscription' && <div><div>
                    {(!!pageContent && pageContent.code === 0) && <div id="lkSubsList">{subs === 0
                        ? (
                            (!pageContent.data.productName)
                                ? <table id="lkSubsTable">
                                    <thead><tr><td>Номер</td><td/><td>Название</td><td>Дата создания</td><td>Статус</td><td/></tr></thead>
                                    <tbody>
                                    {Object.keys(pageContent.data).map((item, index) => {
                                        return <tr key={index}>
                                            <td>#{index + 1}</td>
                                            <td><img alt="" src={pageContent.data[item].cover}/></td>
                                            <td>{pageContent.data[item].productName}</td>
                                            <td>{(pageContent.data[item].subscription && pageContent.data[item].subscription.active_from) ? dateConvert(pageContent.data[item].subscription.active_from) : '-'}</td>
                                            <td>{(pageContent.data[item].subscription && pageContent.data[item].subscription.status) ? pageContent.data[item].subscription.status : ''}</td>
                                            <td><a href={'#/services/subscription?view=' + (index + 1)} onClick={_ => setSubs(index + 1)}>Подробнее</a></td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                                : <table id="lkSubsTable">
                                    <thead>
                                    <tr><td>Номер</td><td/><td>Название</td><td>Дата создания</td><td>Статус</td><td/></tr></thead>
                                    <tbody>
                                    <tr>
                                        <td>#1</td>
                                        <td><img alt="" src={pageContent.data.cover}/></td>
                                        <td>{pageContent.data.productName}</td>
                                        <td>{(pageContent.data.subscription && pageContent.data.subscription.active_from) ? dateConvert(pageContent.data.subscription.active_from) : '-'}</td>
                                        <td>{(pageContent.data.subscription && pageContent.data.subscription.status) ? pageContent.data.subscription.status : ''}</td>
                                        <td><a href={'#/services/subscription?view=1'} onClick={_ => setSubs(1)}>Подробнее</a></td>
                                    </tr>
                                    </tbody>
                                </table>
                        )
                        : <div id="lkSub">
                            <div>
                                <div/>
                                <div>
                                    {
                                        (!pageContent.data.productName)
                                            ? (
                                                Object.keys(pageContent.data).map((item, index) => {
                                                    if (index + 1 === subs) {
                                                        return <div key={index}>
                                                            <div>
                                                                <div><img alt="" src={pageContent.data[item].cover}/></div>
                                                                <div>{pageContent.data[item].productName}</div>
                                                            </div>
                                                            <div>{pageContent.data[item].create_date && <div><b>Дата запуска подписки:</b> {pageContent.data[item].create_date}</div>}</div>
                                                            <div><b>Код активации:</b> {pageContent.data[item].keyList[0].value}
                                                            </div>
                                                            <div>
                                                                {pageContent.data[item].distrList.length > 0 && <div>
                                                                    <div><b>Скачать дистрибутив{pageContent.data[item].distrList.length > 1 ? 'ы' : ''}:</b></div>
                                                                    {pageContent.data[item].distrList.map((item2, index2) => {
                                                                        return <div key={index}>
                                                                            <div><a href={pageContent.data[item].distrList[index2].value}>{pageContent.data[item].distrList[index2].name}</a></div>
                                                                            <br/>
                                                                            <div>
                                                                                <div style={{textAlign: 'center'}}><QRCode title="" value={pageContent.data[item].distrList[index2].value} bgColor={'#FFFFFF'} fgColor={'#000000'} size={171}/></div>
                                                                            </div>
                                                                            <br/>
                                                                        </div>
                                                                    })}
                                                                </div>}
                                                            </div>
                                                            <div id="subsBack"><a href={'#/services/subscription'} onClick={_ => setSubs(0)}> ← к списку</a></div>
                                                        </div>
                                                    }
                                                    return null
                                                })
                                            )
                                            : <div>
                                                <div>
                                                    <div><img alt="" src={pageContent.data.cover}/></div>
                                                    <div>{pageContent.data.productName}</div>
                                                </div>
                                                <div>{pageContent.data.create_date && <div><b>Дата запуска подписки:</b> {pageContent.data.create_date}</div>}</div>
                                                <div><b>Код активации:</b> {pageContent.data.keyList[0].value}
                                                </div>
                                                <div>
                                                    {pageContent.data.distrList.length > 0 && <div>
                                                        <div><b>Скачать дистрибутив{pageContent.data.distrList.length > 1 ? 'ы' : ''}:</b></div>
                                                        <br />
                                                        {pageContent.data.distrList.map((item2, index2) => {
                                                            return <div key={index2}>
                                                                <div>
                                                                    <a href={pageContent.data.distrList[index2].value}>{pageContent.data.distrList[index2].name} (
                                                                        {pageContent.data.distrList[index2].key === 'IOSLINK' ? 'для iOS' : ''}
                                                                        {pageContent.data.distrList[index2].key === 'ANDLINK' ? 'для Android' : ''}
                                                                    )</a>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{textAlign: 'center'}}><QRCode title="" value={pageContent.data.distrList[index2].value} bgColor={'#FFFFFF'} fgColor={'#000000'} size={171}/></div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        })}
                                                    </div>}
                                                </div>
                                                <div id="subsBack"><a href={'#/services/subscription'} onClick={_ => setSubs(0)}> ← к списку</a></div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }</div>}
                </div>
                </div>}
                {tab === '/support' && <div id="support">
                    <div>
                        <div>консультация по продуктам</div>
                        <div>Мы не оставим вас наедине с проблемой и всегда придем на помощь, потому что ничто не может быть ценнее, чем ваше время.</div>
                        <div>Получите оперативную консультацию отдела продаж. Мы поможем выбрать подходящий продукт и тариф, а также ответим на все вопросы.</div>
                        <div>Внимание, режим работы отдела продаж: ПН – ПТ, с 9:00 до 19:00</div>
                        <a href="mailto:support@zsquare.ru">
                            <div className="mailIcon"/>
                            support@zsquare.ru</a>
                    </div>
                    <div>
                        <div>техническая поддержка</div>
                        <div>Техническая поддержка ZSQUARE работает в режиме 24x7. Наши специалисты будут рады оказать квалифицированные технологические и консультационные услуги.</div>
                        <a href="mailto:support@zsquare.ru">
                            <div className="mailIcon"/>
                            support@zsquare.ru</a>
                    </div>
                </div>}
                {tab === '/contacts' && <div id="contacts">
                    <div>
                        <div>Провайдер облачных (подписочных) сервисов в Российской Федерации</div>
                        <div>
                            <div>
                                <div className="icon-icons-18"/>
                                <div>Россия, Москва, ул. Правды, д. 8 корп. 13, офис 523</div>
                            </div>
                            <div>
                                <div className="phoneIcon"/>
                                <a href="tel:88005550827">8-800-555-08-27</a></div>
                            <div>
                                <div className="mailIcon"/>
                                <a href="mailto:support@zsquare.ru">support@zsquare.ru</a></div>
                        </div>
                        <img id="mapx" alt="" src="../img/mapx.jpg"/>
                    </div>
                </div>}
                {tab === '/noAuth' && <div id="noAuth">
                    <div>Этот раздел доступен только авторизированным пользователям</div>
                    <div>Для продолжения работы выполните <a href="#/codeActivation" onClick={_ => setTab('/codeActivation')}>ВХОД</a></div>
                </div>}
            </div>

            <div id="topLine">
                <div><div><div className="phoneIcon"/>Служба технической поддержки: <a href="mailto:support@zsquare.ru">support@zsquare.ru</a></div></div>
            </div>
        </div>
    </div>
}

export default App
