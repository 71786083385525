import React from "react"
import {eIcon} from "helper-func"

function App() {

    document.title = "МТС | Kaspersky Internet Security 3"
    let kis3props = [105,106,107,120]

    return <div className="App">
        <div id="pageWidthKis">
            <div id="kis3header">
                <div><div><img alt="" src={require("./img/kis3.svg").default} /></div><div><div>Kaspersky Internet Security (3&nbsp;устройства)</div></div></div>
            </div>
            <div id="kis3title">
                <div>
                    <b>Kaspersky Internet Security</b> — оптимальная защита ПК. Ищите информацию, совершайте покупки и общайтесь в интернете, не беспокоясь об угрозах. Содержит модули «Родительский контроль» и «Безопасные платежи».
                </div>
            </div>
            <div id="kis3props">
                <div>
                    {kis3props.map((item, i) => {
                        if (!!eIcon.propI[item]) {
                            return <div id="kis3prop" key={i}><div><div className="svg_check svg_color_k" /></div><div>{eIcon.propI[item].ru}</div></div>
                        }
                        return null
                    })}
                    <div id="kis3prop"><div><div className="svg_check svg_color_k" /></div><div>для Windows® | macOS® | Android™ | iOS®</div></div>
                </div>
            </div>
            <div id="kis3link">
                <div>
                    <br />
                    <a href="https://www.kaspersky.ru/downloads/internet-security">Подробнее</a>
                </div>
            </div>
        </div>
    </div>
}

export default App
