import React, {useState} from 'react'
import {ajaxUpdate, urlGuidSearch} from 'helper-func'
import {apiLinkGuid, apiLinkGuidCheck} from 'api-methods'

function Download() {

    function getOS () {
        let osArr = ['macos','ios','windows','android','linux']
        let linkMatch = ['MACLINK','IOSLINK','WINLINK64','ANDLINK','LINLINK64']
        let showOs = (navigator.appVersion).toLocaleLowerCase()
        let link = 'DISTRLINK'
        osArr.forEach((item, i) => {if (showOs.indexOf(item) > -1) {link = linkMatch[i]}})
        return link
    }

    let osAssoc             = {ANDLINK: 'ANDROID', WINLINK64: 'WINDOWS', WINLINK32: 'WINDOWS', MACLINK: 'MacOS', IOSLINK: 'iOS', DISTRLINK: 'ВАШЕЙ ПЛАТФОРМЫ'}
    let platform            = getOS()
    const [name, setName]   = useState('')
    const [data, setData]   = useState({})
    const [desc, setDesc]   = useState('')
    const [load, setLoad]   = useState(true)
    const [oneReq, setOneReq]   = useState(false)
    const [error, setError] = useState('')
    const [subsStatus, setSubsStatus] = useState(false)
    const Loader            = () => {return <div className="wrap-lds-ring"><div className="lds-spinner"><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /></div></div>}
    const PlatformMain      = (props) => {return <div className="platformMain"><div className="platformMain__img" /><div className="platformMain__text">СКАЧАЙТЕ<br/> ПРИЛОЖЕНИЕ<br/> ДЛЯ {osAssoc[props]}</div><a className="platformMain__button" href={data[props]}>Скачать</a></div>}
    const PlatformList      = (props) => {return <div className="platform"><div className={'platform__icon icon-' + props.icon}/><div className="platform__text"><div className="platform__text-title">{props.title}</div><a className="platform__text-link" href={props.link}>Скачать</a></div></div>}

    async function apiData () {
        if (urlGuidSearch(2)) {
            await apiLinkGuid(urlGuidSearch(2)).then(data => {
                if (data.code + '' === '0') {
                    apiLinkGuidCheck(data)
                    !!data.data.productName ? setName(data.data.productName) : ''
                    !!data.data.description ? setDesc(data.data.description) : ''
                    setSubsStatus(data.data.status === 'active')
                    let obj = {}
                    if (!!data.data.distrList) {data.data.distrList.forEach(item => {if (!!item['key'] && !!item['value']) {obj[item['key']] = item['value'];}})}
                    setData(obj)
                } else {
                    setError(!!data.message ? data.message : 'Произошла ошибка')
                }
                setLoad(false)
            })
        } else {
            setLoad(false)
        }
    }

    if (!oneReq) {setOneReq(true);apiData().then()}

    // 1.4. clear, resize, ajaxUpdate ↓
    if (document.location.hash === '#postmessage') {
        const [resizeValue, setResizeValue] = useState(800)
        function heightSend () {
            let actualPageSize = document.body.clientHeight + 40
            if (resizeValue !== actualPageSize) {
                setResizeValue(actualPageSize)
                window.parent.postMessage({height: actualPageSize}, '*')
            }
        }
        const [resizeEvent, setResizeEvent] = useState(false)
        if (!resizeEvent) {
            setResizeEvent(true)
            window.addEventListener('resize', function(event) {heightSend()}, true)
        }
        setTimeout(() => {heightSend()}, ajaxUpdate)
    }
    // 1.4. clear, resize, ajaxUpdate ↑

    return load ? <Loader/> : <div id="page">
        <div className="logo"><div className="logoMts" />{name && <div className="product">{name}</div>}</div>

        {error + '' !== '' ? <div className="title">{error}</div> : (subsStatus ? <div>
            {((platform === 'WINLINK64' || platform === 'WINLINK32') && (data['WINLINK64'] || data['WINLINK32'])) && PlatformMain(data['WINLINK64'] ? 'WINLINK64' : 'WINLINK32')}
            {(platform === 'ANDLINK' && data['ANDLINK']) && PlatformMain('ANDLINK')}
            {(platform === 'IOSLINK' && data['IOSLINK']) && PlatformMain('IOSLINK')}
            {(platform === 'MACLINK' && data['MACLINK']) && PlatformMain('MACLINK')}
            {(!data.hasOwnProperty(platform) && data['DISTRLINK']) && PlatformMain('DISTRLINK')}

            {((platform !== 'WINLINK64' && (data['WINLINK64'] || data['WINLINK32'])) || (platform !== 'ANDLINK' && data['ANDLINK']) || (platform !== 'IOSLINK' && data['IOSLINK']) || (platform !== 'MACLINK' && data['MACLINK'])) && (
                (((platform === 'WINLINK64' || platform === 'WINLINK32') && (data['WINLINK64'] || data['WINLINK32'])) || (platform === 'ANDLINK' && data['ANDLINK']) || (platform === 'IOSLINK' && data['IOSLINK']) || (platform === 'MACLINK' && data['MACLINK']))
                    ? <div className="title">Приложение для других платформ</div> : <div className="title">Выберите дистрибутив</div>
            )}

            {((platform !== 'WINLINK64' && platform !== 'WINLINK32') && (data['WINLINK64'] || data['WINLINK32'])) && <PlatformList icon="Win" title="Windows" link={data['WINLINK64'] ? data['WINLINK64'] : data['WINLINK32']} />}
            {(platform !== 'ANDLINK' && data['ANDLINK']) && <PlatformList icon="Android" title="Android" link={data['ANDLINK']} />}
            {(platform !== 'IOSLINK' && data['IOSLINK']) && <PlatformList icon="IOS" title="iOS" link={data['IOSLINK']} />}
            {(platform !== 'MACLINK' && data['MACLINK']) && <PlatformList icon="MacOS" title="Mac OS" link={data['MACLINK']} />}

            {desc && <div><br/><br/><div className="title">Как воспользоваться премиум-подпиской</div><br/><div className="description" dangerouslySetInnerHTML={{__html: desc}}/></div>}
            <br /><br /><br /><br /><br />
        </div> : <div><br /><br /><div className="title">Подписка не активна</div></div>)}
    </div>
}

export default Download
